@import "../../styles/theme.scss";

// Updated Stylings
.survey_main_container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.answer_container {
  display: flex;
  padding-left: 3%;
  padding-right: 3%;
}

.answer_container_this_or_that {
  display: flex;
  flex-direction: column;
  height: 85vh;
  align-self: center;
}

.answer_container_text {
  @extend .answer_container;
  height: 82vh;
  width: 99%;
  flex-direction: row;
  justify-content: space-between;
}

.answer_container_text_mobile {
  @extend .answer_container;
  width: 99%;
  align-self: center;
  height: auto;
  flex-direction: column;
}

.answer_container_vertical {
  @extend .answer_container;
  height: 65vh;
  flex-direction: column;
  align-self: center;
}

.description_container {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin: 0px 15px 30px 15px;
  align-items: center;
}

.description_container.third {
  width: calc(33% - 30px);
}

.description_title {
  font-family: "Sofia Pro Regular";
  font-size: 32px;
  line-height: 32px;
  font-weight: bold;
  margin-top: 15px;
}


.description_title_selected {
  @extend .description_title;
  color: white;
}

.simple_description:hover .description_title {
  color: white;
}

.answer_title {
  font-size: medium;
  font-weight: bold;
}

.answer_title_this_or_that {
  font-size: 30px;
  font-weight: bold;
  align-self: center;
  margin-bottom: 15px;
}

.answer_description {
  font-size: 14px !important;
  padding-left: 10px;
  width: 120px;
  height: 50px;
  text-align: center;
}

.answer_description_this_that {
  margin-top: 15px;
  padding-left: 10px;
  text-align: center;
}

.this_that_container {
  width: calc(50% - 30px);
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin: 0 6px 6px 6px;
  align-items: center;
}

.checkbox_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  width: 200px;
}

.checkbox_answer {
  display: flex;
  height: 75px;
  border-radius: 16px;
  width: 75px;
  border: 3px solid $stormyBlue;
}

.checkbox_answer_active {
  @extend .checkbox_answer;
  border: 3px solid $stormyBlue;
  background-color: $stormyBlue;
  box-shadow: 2px 12px 14px rgba(28, 34, 51, .2);
  transition: border-color .3s ease, box-shadow .3s ease, -webkit-box-shadow .3s ease, background-color .3s ease;

}

.checkbox_text {
  display: flex;
  align-self: center;
  font-size: 40px;
}

.date_picker {
  display: 'flex';
  font-size: 40px;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 30%;
  color: $charcoalGrey !important;
  border-color: #F6F6F6;
  border-width: 0px;
  background-color: #F6F6F6;
  width: 40vw;
  align-self: center;
  justify-content: center;
  text-align: center;
}

.date_picker_mobile {
  display: 'flex';
  font-size: 40px;
  font-weight: bold;
  padding-left: 10px;
  color: $charcoalGrey !important;
  border-color: #F6F6F6;
  border-width: 0px;
  background-color: #F6F6F6;
  width: 80vw;
  margin-top: 10vh;
  align-self: center;
  justify-content: center;
  text-align: center;
}

.date_picker_container {
  align-self: center;
  width: 400px;
  height: 400px;
  justify-content: center;
}

.description_row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

// Scrollable sections
.photo_grid_scroll_column {
  display: flex;
  flex-direction: column;
  padding-bottom: 10vh;
}

.text_input_single {
  // font-family: "Sofia Pro Regular";
  letter-spacing: 0px;
  width: auto;
  min-width: 80vw;
  font-size: calc(100% / 1.2) !important;
  border: 0px;
  align-self: center;
}

.text_input_container {
  width: auto;
  align-self: center;
  height: 200px;
  padding: 10%;
}