@import "../../styles/theme.scss";

.answer_container {
  display: flex;
  padding-left: 3%;
  padding-right: 3%;
}

.answer_container_scroll {
  @extend .answer_container;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.answer_container_scroll_mobile {
  @extend .answer_container;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}