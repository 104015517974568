@import "../../styles/theme.scss";
@import "../../assets/Fonts/Raleway-Black.ttf";

.base_button_web {
  display: flex;
  margin-left: 30px;
  cursor: default;
  background-color: $stormyBlue;
  color: white !important;
  opacity: 1.6;
  border-radius: 43px;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 130px;
}

.base_button_web:hover {
  background-color: white;
  height: 34px;
  width: 124px;
  border: 3px solid $stormyBlue;
  color: $stormyBlue !important;
}

.base_button_mobile {
  display: flex;
  cursor: default;
  background-color: $stormyBlue;
  color: white !important;
  opacity: 1.6;
  border-radius: 43px;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 10px 0 10px 0;
  min-width: 130px;
}

// .base_button_mobile:hover {
//   background-color: white;
//   height: 34px;
//   border: 3px solid $stormyBlue;
//   color: $stormyBlue !important;
// }

.disabled_button_web {
  @extend .base_button_web;
  background-color: rgb(222, 222, 222);
  color: white !important;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.disabled_button_web:hover {
  background-color: rgb(222, 222, 222);
  color: white !important;
  border: 3px solid $charcoalGrey;
}

.back_button_arrow_container {
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.back_button_arrow_container:hover {
  box-shadow: 0px 5px 15px 0px $lightGrey;
}

.alt_button_web {
  @extend .base_button_web;
  background-color: rgb(222, 222, 222);
}

.alt_button_web:hover {
  color: $charcoalGrey !important;
  border: 3px solid $charcoalGrey;
}

.to_other_surveys {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
}

.divider_shadow {
  display: flex;
  flex-direction: row;
  height: 10px;
  width: 99vw;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 10px 15px 0px $lightGrey;
}

.divider_shadow_mobile {
  display: flex;
  flex-direction: row;
  height: 5px;
  width: 99vw;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 10px 15px 0px $lightGrey;
}

.photo_grid_full {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  height: 79vh;
  align-self: center;
  align-items: center;
  // padding-bottom: 10%;
  overflow-y: auto;
}

.description_grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1200px;
  height: 79vh;
  align-self: center;
  align-items: center;
}

.photo_grid_full_feel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 850px;
  height: 79vh;
  align-self: center;
  align-items: center;
  // padding-bottom: 10%;
  overflow-y: auto;
}

.photo_grid_scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 32%;
  min-width: 60vw;
  margin-right: auto;
  height: 89vh;
}

/* Survey Header */
.logo_mobile {
  height: 20px;
  width: 20px;
  align-self: center;
  margin: 5px 0 5px 0
}

.logo {
  height: 50px;
  width: 50px;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.logo:hover {
  border-radius: 25px;
  box-shadow: 2px 12px 14px rgba(28, 34, 51, 0.2);
  transition: border-color 0.3s ease, box-shadow 0.3s ease,
    -webkit-box-shadow 0.3s ease, background-color 0.3s ease;
}

.survey_header {
  display: flex;
  height: 100px;
  flex-direction: column;
  align-items: center;
}

.survey_header_mobile {
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
}

.survey_progress {
  display: flex;
  width: 330px;
  align-items: center;
  justify-content: space-between;
}

.survey_progress_mobile {
  @extend .survey_progress;
  align-self: center;
  width: 90vw;
}

.section_heading {
  font-size: 18px;
  font-weight: 900;
  opacity: 0.3;
}

.line_container {
  display: flex;
  width: 100%;
  height: 22px;
  align-items: center;
}

.line_active {
  display: flex;
  width: 100%;
  height: 3px;
  background-color: $stormyBlue;
}

.line_inactive {
  display: flex;
  width: 100%;
  height: 3px;
  background-color: white;
}

.line {
  display: flex;
  width: 100%;
  height: 3px;
  background-color: $stormyBlueOpaic;
}

.line_outside_active {
  display: flex;
  margin-top: 47.5px;
  width: 100%;
  height: 3px;
  background-color: $stormyBlue;
}

.line_outside {
  display: flex;
  margin-top: 48px;
  width: 100%;
  height: 3px;
  background-color: $stormyBlueOpaic;
}

.section_container {
  display: flex;
  height: 70px;
  width: 140px;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-between;
}

.divider {
  position: absolute;
  margin-left: 15%;
  margin-right: 15%;
  height: 3px;
  width: 600px;
  background-color: $stormyBlue;
}

.section_heading_active {
  @extend .section_heading;
  opacity: 1;
  color: $stormyBlue !important;
}

.section_circle {
  border-radius: 16pt;
  background-color: $stormyBlueOpaic;
  height: 16pt;
  width: 16pt;
}

.section_circle_active {
  @extend .section_circle;
  background-color: $stormyBlue;
}

/* Question Container */

.question_container {
  display: flex;
  // margin-right: 3%;
  height: auto;
  width: auto;
  margin-top: 5vh;
  margin-bottom: auto;
}

.question_container_vertical {
  @extend .question_container;
  flex-direction: column;
}

.question_container_vertical_mobile {
  @extend .question_container;
  flex-direction: column;
  margin-top: 10px;
}


.message_container {
  @extend .question_container;
  width: 40vw;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

// .message_heading {
//   @extend .question_heading_horizontal;
//   text-align: center;
//   align-self: center;
// }

.question_container_horizontal {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}

.question_text_container {
  display: flex;
  flex-direction: column;
  width: 30vw;
}

.submit_message_container {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40vw;
  margin-top: 10vh;
}

.submit_message_container_mobile {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.message_container_mobile {
  display: flex;
  width: 100vw;
  flex-direction: column;
  text-align: center;
}

.submit_button_container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Survey Footer */
.footer_container {
  display: flex;
  height: 80px;
  max-height: 12vh;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 3vw;
  position: fixed;
  align-items: center;
  opacity: 0.9;
  background-color: white;
  align-self: flex-end;
  left: 0px;
  bottom: 0px;
  width: 99vw;
  z-index: 999;
}

.footer_container_mobile {
  display: flex;
  height: 80px;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 3vw;
  position: fixed;
  align-items: center;
  opacity: 0.9;
  background-color: white;
  width: 99vw;
  align-self: flex-end;
  left: 0px;
  bottom: 0px;
  z-index: 999;
}

.footer_left_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 69vw;
}

.footer_right_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5vw;
  width: 50vw;
}

.footer_container_text {
  display: flex;
  height: 80px;
  justify-content: flex-end;
  padding-right: 5vw;
  align-items: center;
  position: fixed;
  right: 0px;
  bottom: 3px;
  width: 99vw;
}

.footer_container_text_mobile {
  display: flex;
  height: 80px;
  justify-content: flex-end;
  padding-right: 5vw;
  align-items: center;
  position: fixed;
  right: 0px;
  bottom: 3px;
  width: 95vw;
}

.footer_skip {
  cursor: pointer;
  margin-left: 30px;
  text-decoration: underline;
}


.back_button {
  cursor: default;
  background-color: #282c34;
  color: white !important;
  opacity: 0.8;
  margin-right: 1.25%;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 0.75%;
  padding-bottom: 0.75%;
}

.next_button {
  cursor: default;
  background-color: $stormyBlue;
  color: white !important;
  opacity: 1.6;
  margin-left: 1.25%;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 0.75%;
  padding-bottom: 0.75%;
}

/* INPUT SPECIFIC */
.input_grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 66vw;
  height: 90vh;
  background-color: #F6F6F6;
}

.simple_text {
  display: flex;
  align-self: center;
  flex-direction: column;
  background-color: #F6F6F6;
  border-color: #F6F6F6;
  border-width: 0;
  height: 400px;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #9E9E9E !important;
  letter-spacing: 0px;
}

.simple_text:focus {
  border-color: #F6F6F6;
  border-width: 1;
}

.simple_text_selected {
  @extend .simple_text;
  border-color: #F6F6F6;
  border-width: 1;
}

.answer_container {
  display: flex;
  padding-left: 3%;
  padding-right: 3%;
}

.answer_container_scroll {
  @extend .answer_container;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
}

.answer_container_scroll_feel {
  @extend .answer_container;
  width: 100px;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
}

.this_or_that {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.this_that_options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 15vh;
  width: 100%;
  align-items: center;
}

.footer_this_that {
  cursor: default;
  background-color: white;
  border: 3px $stormyBlue solid;
  font-size: 20px;
  width: 300px;
  color: $stormyBlue !important;
  opacity: 1.6;
  margin-left: 1.25%;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1.25%;
  padding-bottom: 1.25%;
  text-align: center;
}

.footer_this_that:hover {
  background-color: $stormyBlue;
  color: white !important;
}

.footer_this_that_neither {
  @extend .footer_this_that;
  border-color: $charcoalGrey;
  color: $charcoalGrey !important;
}

.footer_this_that_neither:hover {
  @extend .footer_this_that;
  border-color: $charcoalGrey;
  background-color: $charcoalGrey;
}