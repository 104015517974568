div {
  color: #3D4543;
  font-family: "Sofia Pro Regular";
  font-size: 20px;
  line-height: 24px;
}

h1 {
  color: #3D4543;
  font-family: "Canela-Regular-Web";
  font-size: 40px;
  line-height: 48px;
}

h2 {
  color: #3D4543;
  font-family: "Sofia Pro Regular";
  font-size: 18px;
  line-height: 26px;
}

input {
  color: #3D4543;
  font-family: "Sofia Pro Regular";
  letter-spacing: 2.5pt;
  font-size: 26px;
  line-height: 30px;
  outline: none;
}

textarea {
  color: #3D4543;
  font-family: "Sofia Pro Regular";
  letter-spacing: 2.5pt;
  font-size: 26px;
  line-height: 30px;
  outline: none;
  resize: none
}