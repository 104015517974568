// $autumnOrange: #d9912a;
// $background-color-secondary: #203731;
// $background-color: #386439;
// $brownGrey: #a1a1a1;
// $button-background-color: #386439;
// $button-text-color: #ffffff;
// $charcoalGrey: #393e41;
// $darkGrey: #7a7a7a;
// $dirtyOrange: #c57809;
// $facebookBlue: #3a599d;
// $faded: #808080;
// $fadedOrange: #eca949;
// $greyishBrown: #4a4a4a;
// $greyishTeal: #5db99d;
// $greyishTealActive: #3a7462;
// $greyishTealHover: #53a68d;
// $hospitalGreen: #9bd1a2;
// $lightGrey: #9b9b9b;
// $neutralGrey: #b0b0b0;
// $pale: #edebe9;
// $pine: #386439;
// $text-color-primary: #000;
// $text-color-secondary: #ffffff;
// $transparentWhite: rgba(255, 255, 255, 0.75);

// Updates from zeplin
$coolGrey: #96a1a8;
$warmGrey: #7d7d7d;
$lightGrey: #dedede;
$background-side-bar: #23272b;
$charcoalGrey: #3D4543;
$pink: #FFE3ED;
$earth: #F3E5D5;
$quartz: #F4CDD4;
$pinkDarker: #fde6e1;

// Layerist
$beige: #f3e5d5;
// previous:4a909d
$stormyBlue: #336472;
$stormyBlueOpaic: rgba(#336472, .3);
$stormyBlueLight: rgba(#336472, .8);
$stormyBlueDark: rgba(#55595a, 1.8);
$stormyOpacityRGB: rgba(51, 100, 114, 0.3);
$calmGrey: #F6F6F6;

// Fonts
$varela: VarelaRound-Regular;

:export {
  // colors

  // Updates from zeplin
  coolGrey: $coolGrey;
  warmGrey: $warmGrey;
  lightGrey: $lightGrey;

  // Layerist
  beige: $beige;
  stormyBlue: $stormyBlue;
  stormyBlueOpaic: $stormyBlueOpaic;
  stormyBlueLight: $stormyBlueLight;
  stormyBlueDark: $stormyBlueDark;
  calmGrey: $calmGrey;
  charcoalGrey: $charcoalGrey;
  pink: $pink;
  earth: $earth;
  quartz: $quartz;


  //fonts
  varela: $varela;
}