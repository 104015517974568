@import "../../styles/theme.scss";

.icon {
  width: 20px;
  margin: 5px;
}

.simple_photo {
  max-width: 20vh;
  border-radius: 16px;
}

.reg_selector_active {
  @extend .simple_photo;
  position: sticky;
  z-index: 100;
  background-color: $stormyBlueDark;
}

.reg_selector {
  display: flex;
}

.simple_photo:hover {
  box-shadow: 2px 12px 14px rgba(28, 34, 51, 0.2);
  transition: border-color 0.3s ease, box-shadow 0.3s ease,
    -webkit-box-shadow 0.3s ease, background-color 0.3s ease;
}

.simple_photo_selected {
  @extend .simple_photo;
  box-shadow: 2px 12px 14px rgba(28, 34, 51, 0.4);
  transition: border-color 0.3s ease, box-shadow 0.3s ease,
    -webkit-box-shadow 0.3s ease, background-color 0.3s ease;
  opacity: 0.4;
}

.simple_photo_selected:hover {
  box-shadow: 2px 12px 14px rgba(28, 34, 51, 0.35);
  transition: border-color 0.3s ease, box-shadow 0.3s ease,
    -webkit-box-shadow 0.3s ease, background-color 0.3s ease;
}

.this_that_photo {
  width: 100%;
  border-radius: 16px;
  box-shadow: 2px 12px 14px rgba(28, 34, 51, 0.1);
}

.this_that_photo_selected {
  @extend .simple_photo_selected;
}

.simple_description {
  cursor: default;
  background-color: $pinkDarker;
  border-radius: 16px;
  border: 3px solid $pinkDarker;
  color: $charcoalGrey;
  display: flex;
  flex-direction: column;
  height: 190px;
  justify-content: center;
  padding-left: 15px;
  margin-top: 10px;
  padding-right: 15px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  width: 160px;
}

.simple_description:hover {
  background-color: $stormyBlue;
  border-color: $stormyBlue;
  box-shadow: 2px 12px 14px rgba(74, 144, 157, 0.35);
  transition: border-color 0.3s ease, box-shadow 0.3s ease,
    -webkit-box-shadow 0.3s ease, background-color 0.3s ease;
  opacity: 1;
}

.simple_description_selected {
  @extend .simple_description;
  background-color: $stormyBlue;
  border-color: $stormyBlue;
  box-shadow: 2px 12px 14px rgba(74, 144, 157, 0.4);
  transition: border-color 0.3s ease, box-shadow 0.3s ease,
    -webkit-box-shadow 0.3s ease, background-color 0.3s ease;
  opacity: 0.8;
}

.scroll_photo {
  max-height: 15vw;
  max-width: 17vw;
  border-radius: 16px;
  margin-bottom: 1vw;
  background-color: white;
}

.scroll_photo:hover {
  box-shadow: 2px 12px 14px rgba(28, 34, 51, 0.2);
  transition: border-color 0.3s ease, box-shadow 0.3s ease,
    -webkit-box-shadow 0.3s ease, background-color 0.3s ease;
}

.scroll_photo_selected {
  @extend .scroll_photo;
  position: sticky;
  z-index: 0;
  box-shadow: 2px 12px 14px rgba(28, 34, 51, 0.4);
  transition: border-color 0.3s ease, box-shadow 0.3s ease,
    -webkit-box-shadow 0.3s ease, background-color 0.3s ease;
  opacity: 0.7;
  background-color: white;
}

.scroll_photo_selected:hover {
  box-shadow: 2px 12px 14px rgba(28, 34, 51, 0.35);
  transition: border-color 0.3s ease, box-shadow 0.3s ease,
    -webkit-box-shadow 0.3s ease, background-color 0.3s ease;
}

.scroll_photo_container_active {
  @extend .scroll_photo;
  position: sticky;
  width: 210px;
  z-index: 100;
  background-color: $stormyBlueDark;
}

.scroll_photo_container {
  display: flex;
}

.grid_header {
  display: flex;
  background-color: $stormyBlue;
  height: 75px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.next {
  background-color: #282c34;
  display: flex;
  height: 75px;
  align-items: center;
  justify-content: center;
}

/* PHOTO UPLOAD */

.upload_container {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  margin-top: 5vh;
  margin-bottom: 5vh;
  align-items: center;
}

.upload_button {
  display: flex;
  width: 120px;
  height: 80px;
  background-color: $stormyBlue;
  align-items: center;
  text-align: center;
  color: white;
  border-radius: 16px;
}

.upload_button:hover {
  border: 3px solid $stormyBlue;
  color: $stormyBlue;
  background-color: white;
}

.photo_container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 66vw;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding-bottom: 80px;
}

.photo_upload_container {
  display: flex;
  align-self: center;
  align-items: center;
  margin-bottom: 5%;
  justify-content: space-between;
}

.photo_upload_button {
  border-radius: 16px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  align-self: center;
  text-align: center;
  color: white !important;
  font-size: medium;
  font-weight: bold;
  border-color: $stormyBlue;
  background-color: $stormyBlue;
}

.photo_upload_text {
  font-size: medium;
}

.photo {
  height: 300px;
}

/**
 * Icon
 */

.icon {
  position: relative;
  overflow: hidden;
  // width: 50px;
  // height: 50px;
  display: inline-block;

  // margin: 25px 0 25px 25px;
  border-radius: 5px;
  color: #fff !important;
  text-decoration: none;
  text-align: center;
  font-size: 12px;
  font-family: sans-serif;
}

/**
 * The "shine" element
 */

.icon:after {
  animation: shine 5s ease-in-out infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%);
}

/* Hover state - trigger effect */

/* Active state */

.icon:active:after {
  opacity: 0;
}

@keyframes shine {
  10% {
    opacity: 0.9;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }

  100% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

.svg_image:hover {
  color: white;
  background-color: #336472;
}